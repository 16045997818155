<template>
  <div class="app-container deciration-guideline-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler">{{
          this.$t("commons.addNew")
        }}</el-button>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <el-dialog
      :visible.sync="guideObj.dialogVisible"
      :before-close="handleClose"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ guideObj.dialogTitle }}</span>
      </template>
      <DecorationGuideDialog
        v-if="guideObj.dialogVisible"
        :businessStageList="businessStageList"
        :id="guideObj.id"
        @close="handleClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import {
  getGuideList,
  deleteGuide,
} from "@/api/ruge/bms/customerService/docurationGuide.js";
import DecorationGuideDialog from "./decorationGuideDialog.vue";
import moment from "moment";
export default {
  name: "decirationGuidelineComponent",
  components: {
    finalTable,
    DecorationGuideDialog,
  },
  data() {
    return {
      businessStageList: [],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "lastUpdateDate", label: "更新时间", width: "400" },
          { prop: "businessStage", label: "阶段", width: "" },
          { prop: "sort", label: "序号", width: "" },
          { prop: "businessName", label: "业务名称", width: "" },
          { prop: "showApplet", label: "是否在小程序显示", width: "" },
          { prop: "operation", label: "操作", width: "110" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          lastUpdateDate: {
            type: "dateRange",
            actionType: "goSearch",
            value: [null, null],
          },
          businessStage: {
            type: "selector",
            label: "阶段",
            value: "",
            placeholder: "请选择阶段",
            actionType: "goSearch",
            optionList: [],
          },
          sort: {
            type: "numberInput",
            label: "更新序号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入序号",
            prefixIcon: "el-icon-search",
          },
          businessName: {
            type: "input",
            label: "业务名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入业务名称",
            prefixIcon: "el-icon-search",
          },
          showApplet: {
            type: "selector",
            label: "是否在小程序显示",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否在小程序显示",
            optionList: [
              {
                label: "是",
                value: "true",
              },
              {
                label: "否",
                value: "false",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          showApplet: {
            type: "enumerationColumn",
            emuList: {
              true: "是",
              false: "否",
            },
          },
          businessStage: {
            type: "enumerationColumn",
            emuList: {},
          },
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          businessStage: {
            inline: true,
            value: "",
          },
          sort: {
            inline: true,
            value: "",
          },
          businessName: {
            inline: true,
            value: "",
          },
          showApplet: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      listLoading: false,
      guideObj: {
        dialogVisible: false,
        dialogTitle: "",
        id: "",
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
      },
    };
  },
  watch: {
    listLoading: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableList();
    this.initStageList();
  },
  methods: {
    initStageList() {
      findLookupItem("BUSI_STAGE_LIST").then((res) => {
        console.log("initStageList", res);
        this.businessStageList = res.map((item) => {
          this.dataset.detailConfig.businessStage.emuList[
            String(item.itemIndex)
          ] = item.itemName;
          return {
            value: String(item.itemIndex),
            label: item.itemName,
          };
        });
        this.dataset.searchLineConfig.businessStage.optionList =
          this.businessStageList;
      });
    },
    addNewHandler() {
      this.guideObj.id = "";
      this.guideObj.dialogTitle = "新增装修指引";
      this.guideObj.dialogVisible = true;
    },
    getTableList() {
      this.listLoading = true;
      getGuideList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (
          datas.params.lastUpdateDate &&
          datas.params.lastUpdateDate.length > 0
        ) {
          this.requestParams.startTime = datas.params.lastUpdateDate[0]
            ? new Date(datas.params.lastUpdateDate[0]).getTime()
            : null;
          this.requestParams.endTime = datas.params.lastUpdateDate[1]
            ? new Date(datas.params.lastUpdateDate[1]).getTime()
            : null;
          delete datas.params.lastUpdateDate;
        }
        this.requestParams = {
          ...this.requestParams,
          ...datas.params,
        };
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editHandler(datas.row);
            break;
          case "delete":
            this.deleteHandler(datas.row);
            break;
        }
      }
    },
    editHandler({ id }) {
      this.guideObj.id = id;
      this.guideObj.dialogTitle = "编辑装修指引";
      this.guideObj.dialogVisible = true;
    },
    deleteHandler({ id }) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteGuide({ id })
          .then(({ code }) => {
            if (code === 200) {
              this.$message.success("删除指引成功");
              this.getTableList();
            }
          })
          .catch(() => {
            this.$message.warning("删除指引失败");
          });
      });
    },
    handleClose(freshFlag) {
      this.guideObj.dialogVisible = false;
      typeof freshFlag === "boolean" && freshFlag && this.getTableList();
    },
  },
};
</script>

<style lang="less" scoped>
.deciration-guideline-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
}
</style>
